export const requestDemo = async (data, product) => {
  const response = await fetch('https://api.crm.scalingiot.com/request-demo', {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name: data.name,
      email: data.email, 
      company_name: data.company_name,
      message: "I'm interested in " + product + (data.message ? `\n${data.message}` : '')
    })
  });
  return response.json();
};
