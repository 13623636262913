import { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Wifi, LineChart, Cog, Bell } from 'lucide-react';
import ScalingLensAnnimation from './ScalingLens/ScalingLensAnnimation';
import RequestDemo from './ScalingLens/RequestDemo';
import Pricing from './ScalingLens/Pricing';

const ScalingLens = () => {
  const [showRequestDemo, setShowRequestDemo] = useState(false);
  const [showPricing, setShowPricing] = useState(false);

  const features = {
    communication: {
      title: "Communication Failure Detection",
      description: "Real-time monitoring of device connectivity with instant alerts for network disruptions and communication failures.",
      icon: <Wifi className="w-6 h-6 sm:w-8 sm:h-8 text-sky-300" />
    },
    abnormal: {
      title: "Abnormal Reading Detection", 
      description: "Advanced anomaly detection algorithms to identify unusual patterns and outliers in sensor readings.",
      icon: <LineChart className="w-6 h-6 sm:w-8 sm:h-8 text-sky-300" />
    },
    equipment: {
      title: "Equipment Behavior Analysis",
      description: "Continuous monitoring of equipment performance to detect unusual behavior patterns and potential failures.",
      icon: <Cog className="w-6 h-6 sm:w-8 sm:h-8 text-sky-300" />
    },
    alerts: {
      title: "Intelligent Alert System",
      description: "Customizable multi-channel alerts through SMS, email, calls, and push notifications with smart filtering.",
      icon: <Bell className="w-6 h-6 sm:w-8 sm:h-8 text-sky-300" />
    }
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-gray-900 via-blue-900 to-sky-900 overflow-hidden">
      {/* Animated background effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48cGF0dGVybiBpZD0iZ3JpZCIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBwYXR0ZXJuVW5pdHM9InVzZXJTcGFjZU9uVXNlIj48cGF0aCBkPSJNIDQwIDAgTCAwIDAgMCA0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJyZ2JhKDI1NSwyNTUsMjU1LDAuMSkiIHN0cm9rZS13aWR0aD0iMSIvPjwvcGF0dGVybj48L2RlZnM+PHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmlkKSIvPjwvc3ZnPg==')] opacity-20"></div>
      </div>

      <div className="relative container max-w-[2000px] mx-auto px-4 sm:px-6 py-8 sm:py-16 mt-12 md:mt-0">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-8 sm:mb-8"
        >
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-white sm:mb-2">
            ScalingLens
            <img src="/scalinglens-logo.svg" alt="ScalingLens" className="inline-block h-10 sm:h-16 ml-[-8px]" />
          </h2>
          <p className="text-lg sm:text-xl text-sky-200 max-w-4xl mx-auto px-4">
            Advanced anomaly detection and alert system with real-time monitoring of device health, performance, and communication status.
          </p>
        </motion.div>

        <img src="/scalinglens_annimation.svg" alt="Use Case Animation" className="w-full h-full object-contain" />
        
        {/* Feature Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-4 gap-4 sm:gap-8 mb-8 sm:mb-16 mt-8 max-w-[2000px] mx-auto">
          {Object.entries(features).map(([key, feature]) => (
            <div key={key} className="relative group flex-1">
              <div className="absolute inset-0 bg-gradient-to-r from-sky-500/20 to-blue-500/20 rounded-xl blur-xl group-hover:blur-2xl transition-all duration-300"></div>
              <div className="relative bg-black/40 backdrop-blur-xl rounded-xl p-4 sm:p-8 border border-white/10 shadow-2xl group-hover:border-sky-500/50 transition-all duration-300">
                <div className="flex items-center space-x-3 sm:space-x-4 mb-4 sm:mb-6">
                  <div className="p-2 sm:p-3 bg-sky-900/50 rounded-lg group-hover:bg-sky-800/50 transition-colors duration-300">
                    {feature.icon}
                  </div>
                  <h3 className="text-xl sm:text-2xl font-bold text-white">{feature.title}</h3>
                </div>
                <p className="text-sm sm:text-base text-sky-200 leading-relaxed">{feature.description}</p>
                <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-sky-500 to-blue-500 rounded-b-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
              </div>
            </div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="text-center space-x-4"
        >
          <div className="inline-block">
            <button 
              onClick={() => setShowPricing(true)}
              className="px-6 sm:px-8 py-3 sm:py-4 bg-black rounded-lg leading-none flex items-center divide-x divide-gray-600"
            >
              <span className="text-sm sm:text-base text-gray-100 px-2 sm:px-3">Pricing</span>
            </button>
          </div>
          <div className="inline-block">
            <button 
              onClick={() => setShowRequestDemo(true)}
              className="px-6 sm:px-8 py-3 sm:py-4 bg-black rounded-lg leading-none flex items-center divide-x divide-gray-600"
            >
              <span className="text-sm sm:text-base text-gray-100 px-2 sm:px-3">Request a Demo</span>
            </button>
          </div>
        </motion.div>
      </div>

      {/* Subtle white gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-white/5 to-transparent pointer-events-none"></div>

      <RequestDemo isOpen={showRequestDemo} onClose={() => setShowRequestDemo(false)} />
      <Pricing isOpen={showPricing} onClose={() => setShowPricing(false)} />
    </div>
  );
};

export default ScalingLens;