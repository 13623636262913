import { motion } from 'framer-motion';
import ConfigView from './ConfigView';

const BacnetConnector = () => {
  const configExample = {
    "connector_type": "BACnet",
    "network": {
      "mode": "IP",  // IP or MSTP
      "device_id": 1234,
      "port": 47808, // BAC0
      "network_number": 1,
      "broadcast_address": "192.168.1.255"
    },
    "device": {
      "vendor_id": 777,
      "model_name": "ScallingBridge BACnet",
      "segmentation_support": "segmented_both", 
      "max_apdu_length": 1476
    },
    "points": [
      {
        "object_type": "analog_input",
        "instance": 1,
        "name": "outside_temperature",
        "description": "Outside Air Temperature",
        "units": "degrees_celsius",
        "polling_interval": "5s"
      }
    ],
    "discovery": {
      "enabled": true,
      "who_is_interval": "5m",
      "auto_add_devices": false
    },
    "options": {
      "cov_lifetime": "1h",
      "apdu_timeout": "3s",
      "number_of_retries": 3
    }
  };

  return (
    <motion.div 
      className="relative bg-black/40 backdrop-blur-xl rounded-xl p-4 border border-white/10 shadow-2xl text-center"
      initial={{ opacity: 0, scale: 0.8 }} 
      animate={{ opacity: 1, scale: 1 }} 
      transition={{ duration: 0.5 }}
    >
      <h3 className="text-xl font-bold text-white">BACnet (IP/MSTP) Connector</h3>
      <p className="text-sm text-purple-200 leading-relaxed">
        Interface with BACnet IP/MSTP devices using automatic device discovery and point mapping. Supports COV subscriptions, structured device management, and flexible point configuration.
      </p>
      <ConfigView config={configExample} />
      <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-purple-500 to-pink-500 rounded-b-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
    </motion.div>
  );
};

export default BacnetConnector;
