import { motion } from 'framer-motion';
import ConfigView from './ConfigView';

const ModbusConnector = () => {
  const configExample = {
    "connector_type": "Modbus",
    "mode": "TCP", // or "RTU"
    "connection": {
      "host": "192.168.1.100",
      "port": 502,
      "unit_id": 1,
      "timeout": "5s"
    },
    "registers": [
      {
        "type": "holding",  // holding, input, coil, discrete_input
        "address": 4000,
        "length": 2,
        "data_type": "float32",
        "polling_rate": "1s",
        "name": "temperature_sensor"
      }
    ],
    "options": {
      "byte_order": "big_endian",
      "word_order": "big_endian",
      "reconnect_delay": "5s",
      "max_retries": 3
    }
  };

  return (
    <motion.div 
      className="relative bg-black/40 backdrop-blur-xl rounded-xl p-4 border border-white/10 shadow-2xl text-center"
      initial={{ opacity: 0, scale: 0.8 }} 
      animate={{ opacity: 1, scale: 1 }} 
      transition={{ duration: 0.5 }}
    >
      <h3 className="text-xl font-bold text-white">Modbus (TCP/RTU) Connector</h3>
      <p className="text-sm text-purple-200 leading-relaxed">
        Connect to Modbus TCP/RTU devices with flexible register mapping and automatic data conversion. Supports all register types with configurable polling rates and data type conversions.
      </p>
      <ConfigView config={configExample} />
      <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-purple-500 to-pink-500 rounded-b-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
    </motion.div>
  );
};

export default ModbusConnector;
