import Logo from "../components/Logo";
import ScalingBridge from "../components/ScalingBridge";
import ScalingLens from "../components/ScalingLens";
import ScalingCustomers from "../components/ScalingCustomers";

const Home = () => {
  return (
    <div className="min-h-screen">
      <Logo />
      <ScalingBridge />
      <ScalingLens />
      <ScalingCustomers />
    </div>
  );
};

export default Home;
