import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { useState } from 'react';

const Pricing = ({ isOpen, onClose }) => {
  const [selectedPackage, setSelectedPackage] = useState('basic');

  const packages = {
    basic: {
      name: 'Basic',
      price: 0.05,
      features: [
        'Non-changing value detection',
        'On-premises deployment support (T&C apply)',
        'Calls/SMS will be charged separately depending on the country (if enabled)'
      ]
    },
    smart: {
      name: 'Smart', 
      price: 0.08,
      features: [
        'Non-changing value detection',
        'Adaptive out-of-range value detection',
        'Customized value ranges per sensor',
        'On-premises deployment support (T&C apply)',
        'Calls/SMS will be charged separately depending on the country (if enabled)'
      ]
    },
    advanced: {
      name: 'Advanced',
      price: null,
      features: [
        'Non-changing value detection',
        'Adaptive out-of-range value detection',
        'Customized value ranges per sensor',
        'Customized formula-based triggers',
        'AI-based equipment anomaly detection',
        'On-premises deployment support (T&C apply)',
        'Calls/SMS will be charged separately depending on the country (if enabled)'
      ]
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/60 backdrop-blur-sm"
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="relative w-full max-w-2xl my-8"
          >
            {/* Background gradient */}
            <div className="absolute inset-0 bg-gradient-to-r from-sky-500/20 to-blue-500/20 rounded-xl blur-xl"></div>

            {/* Content container */}
            <div className="relative bg-black/40 backdrop-blur-xl rounded-xl p-8 border border-white/10 shadow-2xl">
              {/* Close button */}
              <button
                onClick={onClose}
                className="absolute top-4 right-4 p-2 rounded-lg bg-white/5 hover:bg-white/10 transition-colors"
              >
                <X className="w-5 h-5 text-sky-200" />
              </button>

              {/* Title */}
              <h2 className="text-2xl font-bold text-white mb-6">Pricing</h2>

              {/* Pricing sections */}
              <div className="space-y-4 max-h-[70vh] overflow-y-auto scrollbar-thin scrollbar-thumb-sky-500/20 scrollbar-track-transparent">
                {/* Package selection buttons */}
                <div className="flex gap-2 mb-4">
                  {Object.keys(packages).map((pkg) => (
                    <button
                      key={pkg}
                      onClick={() => setSelectedPackage(pkg)}
                      className={`px-4 py-2 rounded-lg transition-colors ${
                        selectedPackage === pkg 
                          ? 'bg-sky-500 text-white' 
                          : 'bg-white/5 text-sky-200 hover:bg-white/10'
                      }`}
                    >
                      {packages[pkg].name}
                    </button>
                  ))}
                </div>

                <div className="grid grid-cols-1 gap-4">
                  <AnimatePresence mode="wait">
                    <motion.div
                      key={selectedPackage}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      className="bg-white/5 p-4 rounded-lg border border-sky-500/20 hover:border-sky-500/40 transition-colors"
                    >
                      <h4 className="text-xl font-bold text-white mb-2">{packages[selectedPackage].name}</h4>
                      <div className="text-2xl font-bold text-sky-300 mb-4">
                        {packages[selectedPackage].price ? 
                          <>
                            ${packages[selectedPackage].price}
                            <span className="text-sm font-normal text-sky-200"> per sensor/year</span>
                          </> :
                          <span className="text-sky-200">Contact Us</span>
                        }
                      </div>
                      <div className="space-y-3">
                        <div className="pt-3">
                          <p className="text-white font-semibold mb-2">Features:</p>
                          <ul className="text-sky-200 list-disc list-inside space-y-2">
                            {packages[selectedPackage].features.map((feature, index) => (
                              <li key={index}>{feature}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </motion.div>
                  </AnimatePresence>
                </div>

              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Pricing;
