import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { useState } from 'react';

const Pricing = ({ isOpen, onClose }) => {
  const [selectedPackage, setSelectedPackage] = useState('lite');

  const packages = {
    lite: {
      name: 'Lite',
      price: 499,
      sensors: '50,000',
      messages: '1 Billion'
    },
    basic: {
      name: 'Basic',
      price: 899,
      sensors: '100,000',
      messages: '2 Billion'
    },
    medium: {
      name: 'Medium', 
      price: 1499,
      sensors: '200,000',
      messages: '4 Billion'
    },
    large: {
      name: 'Large',
      price: 2999,
      sensors: '500,000',
      messages: '8 Billion'
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/60 backdrop-blur-sm"
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="relative w-full max-w-2xl my-8"
          >
            {/* Background gradient */}
            <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 to-pink-500/20 rounded-xl blur-xl"></div>

            {/* Content container */}
            <div className="relative bg-black/40 backdrop-blur-xl rounded-xl p-8 border border-white/10 shadow-2xl">
              {/* Close button */}
              <button
                onClick={onClose}
                className="absolute top-4 right-4 p-2 rounded-lg bg-white/5 hover:bg-white/10 transition-colors"
              >
                <X className="w-5 h-5 text-purple-200" />
              </button>

              {/* Title */}
              <h2 className="text-2xl font-bold text-white mb-6">Pricing</h2>

              {/* Pricing sections */}
              <div className="space-y-4 max-h-[70vh] overflow-y-auto scrollbar-thin scrollbar-thumb-purple-500/20 scrollbar-track-transparent">
                {/* Cloud Connector Packages section */}
                <section>
                  <h3 className="text-lg font-semibold text-purple-200 mb-3">Cloud Connector Packages</h3>
                  
                  {/* Package selection buttons */}
                  <div className="flex gap-2 mb-4">
                    {Object.keys(packages).map((pkg) => (
                      <button
                        key={pkg}
                        onClick={() => setSelectedPackage(pkg)}
                        className={`px-4 py-2 rounded-lg transition-colors ${
                          selectedPackage === pkg 
                            ? 'bg-purple-500 text-white' 
                            : 'bg-white/5 text-purple-200 hover:bg-white/10'
                        }`}
                      >
                        {packages[pkg].name}
                      </button>
                    ))}
                  </div>

                  <div className="grid grid-cols-1 gap-4">
                    <AnimatePresence mode="wait">
                      <motion.div
                        key={selectedPackage}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        className="bg-white/5 p-4 rounded-lg border border-purple-500/20 hover:border-purple-500/40 transition-colors"
                      >
                        <h4 className="text-xl font-bold text-white mb-2">{packages[selectedPackage].name}</h4>
                        <div className="text-2xl font-bold text-purple-300 mb-4">${packages[selectedPackage].price}<span className="text-sm font-normal text-purple-200">/month</span></div>
                        <div className="space-y-3 text-sm">
                          <p className="text-purple-200">Suitable for buildings with less than {packages[selectedPackage].sensors} sensors (points)</p>
                          <p className="text-purple-200">Up to {packages[selectedPackage].messages} messages per month</p>
                          <div className="pt-3">
                            <p className="text-white font-semibold mb-2">Supported Connectors:</p>
                            <ul className="text-purple-200 list-disc list-inside">
                              <li>OPC (UA/DA)</li>
                              <li>Modbus (TCP/RTU)</li>
                              <li>BACnet (IP/MSTP)</li>
                            </ul>
                          </div>
                        </div>
                      </motion.div>
                    </AnimatePresence>
                  </div>
                </section>

                {/* Built-in Analytics section */}
                <section>
                  <h3 className="text-lg font-semibold text-purple-200 mb-3">ScalingLens</h3>
                  <div className="bg-white/5 p-4 rounded-lg border border-purple-500/20 hover:border-purple-500/40 transition-colors">
                    <div className="flex items-center justify-between mb-2">
                      <p className="text-purple-200 text-sm">Basic plan included <span className="text-white font-bold">FREE</span> with all packages</p>
                      <span className="text-purple-300 font-semibold">$0</span>
                    </div>
                  </div>
                </section>

                {/* Storage section */}
                <section>
                  <h3 className="text-lg font-semibold text-purple-200 mb-3">Time Series Database</h3>
                  <div className="bg-white/5 p-4 rounded-lg border border-purple-500/20 hover:border-purple-500/40 transition-colors">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <h4 className="text-white font-semibold mb-1">Data In</h4>
                        <p className="text-purple-200 text-sm">$0.0025/MB</p>
                        <p className="text-purple-200/60 text-xs">Volume of data written in MB</p>
                      </div>
                      <div>
                        <h4 className="text-white font-semibold mb-1">Query Count</h4>
                        <p className="text-purple-200 text-sm">$0.012 per 100 queries</p>
                        <p className="text-purple-200/60 text-xs">Number of query executions</p>
                      </div>
                      <div>
                        <h4 className="text-white font-semibold mb-1">Storage</h4>
                        <p className="text-purple-200 text-sm">$0.002/GB-hour</p>
                        <p className="text-purple-200/60 text-xs">Total disk usage in GB-hours</p>
                      </div>
                      <div>
                        <h4 className="text-white font-semibold mb-1">Data Out</h4>
                        <p className="text-purple-200 text-sm">$0.09/GB</p>
                        <p className="text-purple-200/60 text-xs">Data transferred out of DB in GB</p>
                      </div>
                    </div>
                  </div>
                </section>

              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Pricing;
