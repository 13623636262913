const Logo = () => {
  return (
    <div className="fixed top-2 sm:top-4 left-1 sm:left-2 md:left-4 lg:left-6 z-50">
      <div className="p-2 rounded-lg bg-gradient-to-r from-gray-900/50 to-gray-800/30 backdrop-blur-sm">
        <img 
          src="/scaling-iot-logo.svg" 
          alt="ScalingIoT Logo"
          className="h-12 sm:h-16 cursor-pointer hover:opacity-80 hover:scale-110 hover:rotate-3 transition-all duration-300 ease-in-out invert"
        />
      </div>
    </div>
  );
};

export default Logo;
