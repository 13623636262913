import React from 'react';
import { motion } from 'framer-motion';

const ConfigView = ({ config }) => {
  const formatValue = (value) => {
    if (typeof value === 'string') {
      return (
        <motion.span 
          className="text-emerald-400"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, staggerChildren: 0.05 }}
        >
          {`"${value}"`.split('').map((char, i) => (
            <motion.span
              key={i}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: i * 0.05 }}
            >
              {char}
            </motion.span>
          ))}
        </motion.span>
      );
    }
    if (typeof value === 'number') {
      return (
        <motion.span 
          className="text-amber-300"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, staggerChildren: 0.05 }}
        >
          {value.toString().split('').map((char, i) => (
            <motion.span
              key={i}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: i * 0.05 }}
            >
              {char}
            </motion.span>
          ))}
        </motion.span>
      );
    }
    if (typeof value === 'boolean') {
      return (
        <motion.span 
          className="text-purple-400"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, staggerChildren: 0.05 }}
        >
          {value.toString().split('').map((char, i) => (
            <motion.span
              key={i}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: i * 0.05 }}
            >
              {char}
            </motion.span>
          ))}
        </motion.span>
      );
    }
    if (value === null) {
      return (
        <motion.span 
          className="text-red-400"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, staggerChildren: 0.05 }}
        >
          {"null".split('').map((char, i) => (
            <motion.span
              key={i}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: i * 0.05 }}
            >
              {char}
            </motion.span>
          ))}
        </motion.span>
      );
    }
    return value;
  };

  const renderJson = (obj, level = 0) => {
    if (Array.isArray(obj)) {
      if (obj.length === 0) return [{ indent: level, content: <span className="text-gray-300">[]</span> }];
      
      const items = obj.map((item, index) => {
        const value = typeof item === 'object' && item !== null
          ? renderJson(item, level + 1)
          : [{ indent: level + 1, content: formatValue(item) }];
        
        const result = value.map((line, i) => ({
          ...line,
          content: <>{line.content}{index < obj.length - 1 && i === value.length - 1 ? ',' : ''}</>
        }));
        
        return result;
      });

      return [
        { indent: level, content: <span className="text-gray-300">[</span> },
        ...items.flat(),
        { indent: level, content: <span className="text-gray-300">]</span> }
      ];
    }

    if (typeof obj === 'object' && obj !== null) {
      const entries = Object.entries(obj);
      if (entries.length === 0) return [{ indent: level, content: <span className="text-gray-300">{'{}'}</span> }];

      const items = entries.map(([key, value], index) => {
        const formattedValue = typeof value === 'object' && value !== null
          ? renderJson(value, level + 1)
          : [{ indent: level + 1, content: formatValue(value) }];

        const keyContent = (
          <>
            <span className="text-blue-400">"{key}"</span>
            <span className="text-gray-400">: </span>
          </>
        );

        const result = formattedValue.map((line, i) => {
          if (i === 0) {
            return {
              indent: line.indent,
              content: (
                <>
                  {keyContent}
                  {line.content}
                  {index < entries.length - 1 && formattedValue.length === 1 ? ',' : ''}
                </>
              )
            };
          }
          return {
            ...line,
            content: <>{line.content}{index < entries.length - 1 && i === formattedValue.length - 1 ? ',' : ''}</>
          };
        });

        return result;
      });

      return [
        { indent: level, content: <span className="text-gray-300">{'{'}</span> },
        ...items.flat(),
        { indent: level, content: <span className="text-gray-300">{'}'}</span> }
      ];
    }

    return [{ indent: level, content: formatValue(obj) }];
  };

  const renderedJson = renderJson(config);

  return (
    <motion.div
      className="relative bg-gray-900 rounded-lg p-6 font-mono text-sm overflow-x-auto"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-purple-500 to-pink-500 rounded-t-lg" />
      <div className="whitespace-pre text-left">
        {renderedJson.slice(0, 15).map((line, index) => (
          <div key={index} className="flex">
            <span className="w-8 text-right text-gray-500 select-none pr-4">
              {index + 1}
            </span>
            <span className="flex-1 text-left">
              <span className="text-gray-300">{line.indent > 0 ? '  '.repeat(line.indent) : ''}</span>
              {line.content}
            </span>
          </div>
        ))}
        <div className="flex">
          <span className="w-8 text-right text-gray-500 select-none pr-4">16</span>
          <span className="flex-1 text-left">
            <span className="text-gray-300">{renderedJson[15]?.indent > 0 ? '  '.repeat(renderedJson[15]?.indent) : ''}</span>
            <span className="text-gray-500">. . . . . . . . . .</span>
          </span>
        </div>
        <div className="flex">
          <span className="w-8 text-right text-gray-500 select-none pr-4">17</span>
          <span className="flex-1 text-left">
            <span className="text-gray-500">. . . . . . . . . .</span>
          </span>
        </div>
      </div>
    </motion.div>
  );
};

export default ConfigView;
