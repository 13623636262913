import { motion } from 'framer-motion';
import ConfigView from './ConfigView';

const OPCConnector = () => {
  const configExample = {
    "connector_type": "OPC",
    "protocol": "UA", // or "DA"
    "connection": {
      "server_url": "opc.tcp://server.example.com:4840", 
      "security_mode": "SignAndEncrypt",
      "security_policy": "Basic256Sha256",
      "auth": {
        "username": "user",
        "password": "****"
      }
    },
    "tags": [
      {
        "node_id": "ns=2;s=Temperature",
        "sampling_rate": "1s",
        "data_type": "float"
      }
    ],
    "options": {
      "auto_reconnect": true,
      "connection_timeout": "30s"
    }
  };

  return (
    <motion.div 
      className="relative bg-black/40 backdrop-blur-xl rounded-xl p-4 border border-white/10 shadow-2xl text-center"
      initial={{ opacity: 0, scale: 0.8 }} 
      animate={{ opacity: 1, scale: 1 }} 
      transition={{ duration: 0.5 }}
    >
      <h3 className="text-xl font-bold text-white">OPC (UA/DA) Connector</h3>
      <p className="text-sm text-purple-200 leading-relaxed">
        Seamlessly connect to industrial OPC UA/DA servers with automatic tag discovery and real-time data synchronization. Supports both modern UA and legacy DA protocols with built-in security features.
      </p>
      <ConfigView config={configExample} />
      <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-purple-500 to-pink-500 rounded-b-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
    </motion.div>
  );
};

export default OPCConnector;
