import { useState } from 'react';
import { motion } from 'framer-motion';
import OPCConnector from './OPCConnector';
import ModbusConnector from './ModbusConnector';
import BacnetConnector from './BacnetConnector';

const ScalingBridgeConnectors = () => {
  const [selectedConnector, setSelectedConnector] = useState('opcua');

  const connectors = {
    opcua: {
      title: 'OPC UA/DA Connector',
      component: <OPCConnector />
    },
    modbus: {
      title: 'Modbus TCP/RTU Connector',
      component: <ModbusConnector />
    },
    bacnet: {
      title: 'BACnet IP/MSTP Connector',
      component: <BacnetConnector />
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto">
      <div className="flex flex-wrap gap-2 mb-2 justify-center">
        {Object.entries(connectors).map(([key, connector]) => (
          <button
            key={key}
            onClick={() => setSelectedConnector(key)}
            className={`px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200 ${
              selectedConnector === key
                ? 'bg-purple-600 text-white'
                : 'bg-black/40 text-purple-200 hover:bg-purple-600/50'
            }`}
          >
            {connector.title}
          </button>
        ))}
      </div>

      <motion.div
        key={selectedConnector}
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 20 }}
        transition={{ duration: 0.3 }}
      >
        {connectors[selectedConnector].component}
      </motion.div>
    </div>
  );
};

export default ScalingBridgeConnectors;
