import { motion } from 'framer-motion';

const ScalingCustomers = () => {
  const customers = [
    {
      name: "Keppel Corporation",
      description: "Leading provider in district heating and cooling systems (DHCS) for sustainable building climate control",
      logo: "/customers/keppel.png",
      image: "/customers/mediapolis.png"
    },
    {
      name: "Murata Manufacturing", 
      description: "Global leader in ceramic passive electronic components, with expertise in capacitors, filters, sensors and high-frequency parts",
      logo: "/customers/murata.png",
      image: "/customers/murata_company.png"
    },
    {
      name: "Azbil Corporation",
      description: "Leading Japanese automation company providing measurement and control technologies across industrial, factory and building automation since 1906",
      logo: "/customers/azbil.png",
      image: "/customers/azbil_company.jpg"
    },
    {
      name: "SUTD",
      description: "Leading university at the forefront of Design x Tech, redefining education and research to build innovative solutions through industry-academia partnerships",
      logo: "/customers/sutd.png",
      image: "/customers/sutd_company.jpg"
    },
    {
      name: "Singapore General Hospital",
      description: "Singapore's largest tertiary hospital providing comprehensive multi-disciplinary care, integrating clinical excellence with research and education as an academic medical center",
      logo: "/customers/sgh.png",
      image: "/customers/sgh_company.jpg"
    },
    {
      name: "IOI Properties Group",
      description: "Leading Malaysian property developer with extensive portfolio spanning townships, retail malls, and hospitality projects including IOI City Mall - Malaysia's largest mall, and luxury hotels like JW Marriott Singapore South Beach",
      logo: "/customers/ioi.png",
      image: "/customers/ioi_company.jpg"
    },
    {
      name: "*SCAPE Co., Ltd.",
      description: "Non-profit organisation and registered charity empowering youth through holistic programmes, communities and facilities to discover and develop their talents and passions",
      logo: "/customers/scape.png",
      image: "/customers/scape_company.jpg"
    },
    {
      name: "Wilmar International Limited",
      description: "Asia's leading agribusiness group with an integrated model spanning the entire agricultural commodity value chain",
      logo: "/customers/wilmar.png",
      image: "/customers/wimar_company.jpg"
    },
    {
      name: "1 North Coast",
      description: "Nine-storey light industrial building in Woodlands North Coast offering flexible space usage, integrated facilities, and enhanced connectivity to Singapore and Malaysia via nearby RTS Link and Causeway",
      logo: "/customers/wnc.png",
      image: "/customers/wnc_company.jpg"
    },
    {
      name: "National University Hospital",
      description: "Singapore's leading university hospital and principal teaching hospital of NUS medical school, with unique strengths in pediatric kidney and liver transplants",
      logo: "/customers/nuh.png",
      image: "/customers/nuh_company.jpg"
    },
    {
      name: "Shimano",
      description: "Established in 1921, Shimano globally operates three key businesses with sales offices and factories worldwide, supplying captivating products to promote bicycle and sports fishing cultures",
      logo: "/customers/shimano.png",
      image: "/customers/shimano_company.jpg"
    },
    {
      name: "Surbana Jurong",
      description: "Global urban, infrastructure and managed services consulting firm with 16,000+ experts across 40+ countries, delivering sustainable solutions from planning and design to delivery and management",
      logo: "/customers/sjc.png",
      image: "/customers/sjc_company.jpg"
    }
  ];

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-gray-900 via-blue-900/20 to-gray-900 overflow-hidden">
      {/* Modern tech grid background */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 opacity-[0.03]">
          <div className="h-full w-full bg-[linear-gradient(90deg,rgba(255,255,255,.2)_1px,transparent_1px),linear-gradient(rgba(255,255,255,.2)_1px,transparent_1px)] bg-[size:3rem_3rem]" />
        </div>
      </div>

      <div className="relative container mx-auto px-4 py-24">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-20"
        >
          <h2 className="text-5xl sm:text-6xl font-bold text-white mb-6">
            Our <span className="bg-gradient-to-r from-blue-400 to-sky-400 bg-clip-text text-transparent">Customers</span>
          </h2>
          <p className="text-lg sm:text-xl text-gray-300 max-w-3xl mx-auto">
            Leading organizations transforming their operations with our innovative IoT solutions
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
          {customers.map((customer, index) => (
            <motion.div
              key={customer.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="group relative bg-gray-900/50 backdrop-blur-sm rounded-xl overflow-hidden border border-gray-800/50 hover:border-blue-500/50 transition-all duration-500 hover:shadow-lg hover:shadow-blue-500/10"
            >
              <div className="aspect-video relative">
                <img
                  src={customer.image}
                  alt={`${customer.name} Implementation`}
                  className="w-full h-full object-cover brightness-90 group-hover:brightness-100 transition-all duration-500 scale-100 group-hover:scale-105"
                  loading="lazy"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-transparent to-transparent opacity-80" />
              </div>
              
              <div className="p-6 relative z-10">
                <div className="h-12 mb-6 flex items-center justify-center">
                  <img
                    src={customer.logo}
                    alt={customer.name}
                    className="h-full w-auto object-contain opacity-90 group-hover:opacity-100 transition-opacity duration-300 filter drop-shadow-lg"
                    loading="lazy"
                  />
                </div>
                
                <h3 className="text-lg font-semibold text-white mb-3 text-center group-hover:text-blue-400 transition-colors duration-300">
                  {customer.name}
                </h3>
                <p className="text-sm text-gray-300/90 line-clamp-3 leading-relaxed">
                  {customer.description}
                </p>
              </div>

              <div className="absolute bottom-0 left-0 w-full h-[2px] bg-gradient-to-r from-blue-500 via-sky-400 to-blue-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500 ease-out" />
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScalingCustomers;
